import React, { useState, KeyboardEvent } from 'react';
import { IonInput, IonButton } from '@ionic/react';
import { SimpleActivityInput } from '../../API';
import BaseModal from '../layout/base.modal';
import { addAction } from '../../services/action.service';
import moment from 'moment';
import { TaskTypes } from '../quests/taskForm';
import { ActionModel } from '../../models/index';
import QuestCard from '../quests/QuestCard';

interface IProps {
  quests: SimpleActivityInput[];
  actions: ActionModel[];
}

export default function QuestList(props: IProps) {
  const { quests, actions } = props;
  const [note, setNote] = useState('');
  const [detailQuests, setDetailQuests] = useState<any[]>([]);

  const [showModal, setShowModal] = useState<SimpleActivityInput>();

  React.useEffect(() => {
    if (!quests) return;
    function doneTimes(q: SimpleActivityInput): number {
      return actions.filter((a) => q.id === a.activityId).length;
    }

    function isQuestComplete(q: SimpleActivityInput): boolean {
      // daily task that is finished
      if (q.type !== TaskTypes.default && q.finished) {
        // finished date is today
        return moment().isSame(q.finished, 'day');
      }
      return false;
    }
    setDetailQuests(
      quests.map((q) => {
        return {
          ...q,
          doneTimes: doneTimes(q),
          isQuestComplete: isQuestComplete(q),
        };
      })
    );
  }, [quests, actions]);

  async function lookForEnter(e: KeyboardEvent<HTMLIonInputElement>) {
    if (e.key === 'Enter' && showModal) {
      await complete(showModal);
    }
  }

  async function complete(activity: SimpleActivityInput) {
    await addAction('task', activity, note);
    setNote('');
    setShowModal(undefined);
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
      {detailQuests.map((q, i) => (
        <QuestCard className="w-11/12 my-2 mx-auto" quest={q} key={i}>
          <button
            hidden={q.isQuestComplete}
            className="py-px px-2 border-2 border-a-w24 shadow rounded text-a-w24 font-semibold text-xs tracking-wide"
            onClick={() => setShowModal(q)}
          >
            Mark done
          </button>
        </QuestCard>
      ))}
      <BaseModal
        title="Any notes for your future self?"
        handleDismissModal={() => setShowModal(undefined)}
        showModal={!!showModal}
      >
        <div className="mt-5 ml-3">
          <IonInput
            autofocus={true}
            autocapitalize="on"
            debounce={200}
            inputmode="text"
            onKeyUp={(e) => lookForEnter(e)}
            onIonChange={(e: CustomEvent) => setNote(e.detail.value)}
            value={note}
            placeholder={`Add your note here`}
          ></IonInput>
          <IonButton
            className="mt-5"
            fill="outline"
            onClick={() => complete(showModal as SimpleActivityInput)}
          >
            Add note and complete
          </IonButton>
        </div>
      </BaseModal>
    </div>
  );
}
