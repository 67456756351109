import React from 'react';
import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import { peopleOutline, peopleCircleOutline } from 'ionicons/icons';

interface AllyListItemProps {
  dateAllied: string;
  name: string;
  online: boolean;
}

export const AllyListItem: React.FC<AllyListItemProps> = React.memo((props) => {
  return (
    <IonItem>
      <IonLabel>
        <h3>{props.name}</h3>
        <p>Acquired: {props.dateAllied}</p>
      </IonLabel>
      {props.online ? (
        <IonIcon color="success" icon={peopleCircleOutline} slot="end" />
      ) : (
        <IonIcon icon={peopleOutline} slot="end" />
      )}
    </IonItem>
  );
});
