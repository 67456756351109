import './theme/tw-compiled.css';
import '@ionic/react/css/core.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import './theme/variables.css';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { IonApp, IonRouterOutlet, getPlatforms, IonToast } from '@ionic/react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Catalog } from './pages/catalog';
import { Home } from './pages/home';
import { WeekViewPage } from './pages/mobile/weekView';
import { Tabs } from './components/layout/tabs';
import { ErrorHandle } from './utils/error.service';
import { HubCapsule, GraphQLError } from './models/typing';
import { Profile } from './pages/profile';
import { browserService } from './services/browser.service';
import moment from 'moment';

interface AppState {
  visible: boolean;
  timeStampLeftApp: Date;
  dailyWelcomeSent: boolean;
}

const App: React.FC = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [errors, setErrors] = useState<GraphQLError[]>([]);
  const [appState, setAppState] = useState<AppState>({
    visible: true,
    timeStampLeftApp: new Date(),
    dailyWelcomeSent: false,
  });
  useEffect(() => {
    const showToast = (data: HubCapsule) => {
      setErrors(data.payload.data);
    };
    ErrorHandle.listenToError(showToast);
    const platforms = getPlatforms();
    setIsLargeScreen(
      platforms.includes('desktop') || platforms.includes('tablet')
    );
    const [state, event] = browserService.appvis();
    document.addEventListener(event, () => {
      const visible = !document[state];
      const newState: Partial<AppState> = {
        visible,
      };
      if (!visible) {
        newState.timeStampLeftApp = new Date();
      }
      setAppState((prev: AppState) => {
        return {
          ...prev,
          ...newState,
        };
      });
    });
  }, []);

  useEffect(() => {
    const visitedToday = moment().isSame(
      moment(appState.timeStampLeftApp),
      'day'
    );
    if (appState.visible && !visitedToday) {
      sendDailyWelcome();
    }
  }, [appState]);

  function sendDailyWelcome() {
    const [title, options]: [string, NotificationOptions] = [
      'Welcome to quests of life',
      {
        body: 'A brand new day is ready to be designed by you',
      },
    ];
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then(() => {
        new Notification(title, options);
      });
    } else {
      new Notification(title, options);
    }
  }

  return (
    <IonApp>
      {isLargeScreen ? (
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/kitchen" component={Catalog} exact />
            <Route path="/" component={Home} exact />
            <Route path="/profile" component={Profile} exact />
            <Route path="/log" component={WeekViewPage} exact />
          </IonRouterOutlet>
        </IonReactRouter>
      ) : (
        <IonReactRouter>
          <Tabs />
        </IonReactRouter>
      )}
      {errors.length > 0
        ? errors.map((error, i) => {
            return (
              <IonToast
                key={i}
                isOpen={true}
                message={error.message}
              ></IonToast>
            );
          })
        : ''}
    </IonApp>
  );
};

// TODO change the authentocator UI, customise
// https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#custom-form-fields

export default withAuthenticator(App, { usernameAlias: 'email' });
