// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';
export * from './enums';
export * from './profile';
export * from './typing';

const { User, Action, Task, Reward, DayPlan, SimpleActivity } = initSchema(
  schema
);

export { User, Action, Task, Reward, DayPlan, SimpleActivity };
