import React from 'react';
import { BasePage } from '../../components/layout/base.page';
import RewardList from '../../components/rewards/rewardsList';

export const RewardPage = () => {
  return (
    <BasePage title="Quests">
      <RewardList />
    </BasePage>
  );
};
