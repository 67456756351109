export enum ModalStatus {
  Hidden,
  Task,
  Reward,
}

export enum TaskTypes {
  default = 'default',
  oneoff = 'oneoff',
  once_daily = 'once_daily', // can be claimed once daily
  main = 'main',
  side = 'side',
  time_bound = 'time bound',
}
