import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { playOutline, stopOutline } from 'ionicons/icons';
import { PickerColumnOption } from '@ionic/core';
import { Picker } from '../picker/picker';

import styles from './pomodoro.module.css';
import { minutesList } from './minutesList';

interface PomodoroSelectedValue {
  minutes: PickerColumnOption;
}

interface PomodoroProps {
  timerEnded: (time: string) => void;
}

export const Pomodoro = React.memo(({ timerEnded }: PomodoroProps) => {
  const dashArrayStroke = 440;
  const msInMinuteRef = 60000;
  const msInSecondRef = 1000;

  const [pickerIsOpen, setPickerIsOpen] = useState<boolean>(false);
  const [currentMinutes, setCurrentMinutes] = useState<number>(0);
  const [currentSeconds, setCurrentSeconds] = useState<number>(0);
  const [currentStroke, setCurrentStroke] = useState<number>(440);

  const currentInterval = useRef<NodeJS.Timer>();

  const totalMinutes = useRef(0);
  const totalSeconds = useRef(0);
  const totalRemainingMs = useRef(0);
  const userSelectedTimeAsText = useRef('30');
  const userSelectedTimeInMs = useRef(0);

  const calmSound = new Audio('../../../assets/klankbeeld__forest-calm.wav');
  const finishSound = new Audio('../../../assets/ping.wav');
  // timerEnded(userSelectedTimeAsText.current);
  const pauseButtonEl = useRef<any>();

  const confirmedTime = (value: PomodoroSelectedValue): void => {
    setPomodoroTime(value.minutes.value);

    setPickerIsOpen(false);
  };

  const cancelledPicker = (): void => {
    setPickerIsOpen(false);
  };

  const openPicker = (): void => {
    setPickerIsOpen(true);
  };

  const resetPomodoro = useCallback(() => {
    stopPomodoro();
    totalSeconds.current = 0;
    totalMinutes.current = 0;

    setCurrentSeconds(() => 0);
    setCurrentMinutes(() => 0);
  }, []);

  const setPomodoroTime = (minutes = '30') => {
    const mins = parseInt(minutes, 10);

    const totalMs = mins * msInMinuteRef;

    setCurrentMinutes(mins);
    setCurrentSeconds(0);

    totalMinutes.current = mins;
    totalSeconds.current = 0;
    totalRemainingMs.current = totalMs;
    userSelectedTimeAsText.current = minutes;
    userSelectedTimeInMs.current = totalMs;
  };

  const startPomodoro = async (): Promise<void> => {
    if (totalRemainingMs.current > 0 && !currentInterval.current) {
      const interval = setInterval(() => {
        updateCurrentTime();
        updateProgress();
      }, msInSecondRef);

      currentInterval.current = interval;
    }
  };

  const stopPomodoro = (): void => {
    currentInterval.current && clearInterval(currentInterval.current);

    currentInterval.current = undefined;
  };

  const updateCurrentTime = async (): Promise<void> => {
    const newTotalTime = totalRemainingMs.current - 1000;

    totalRemainingMs.current = newTotalTime;

    if (newTotalTime <= 0) {
      resetPomodoro();
      // calmSound.stop();
      finishSound.play();
      return;
    }

    const newSeconds = totalSeconds.current - msInSecondRef;

    const secondsZeroOrMore = newSeconds > -1;

    const newMinutes = secondsZeroOrMore
      ? totalMinutes.current
      : totalMinutes.current - 1;

    const minutesZeroOrMore = newMinutes > -1;

    totalMinutes.current = minutesZeroOrMore
      ? newMinutes
      : (msInMinuteRef * 59) / msInMinuteRef;

    totalSeconds.current = secondsZeroOrMore
      ? newSeconds
      : msInMinuteRef - 1000;

    setCurrentSeconds(() => totalSeconds.current / msInSecondRef);
    setCurrentMinutes(() => totalMinutes.current);
  };

  const updateProgress = (): void => {
    // const progress =
    //   (1 - totalRemainingMs.current / userSelectedTimeInMs.current) / 1; between 0 / 1

    const strokeProgress =
      100 - (totalRemainingMs.current / userSelectedTimeInMs.current) * 100; // between 0 / 100

    setCurrentStroke(440 - (440 * strokeProgress) / 100);
  };

  useEffect(() => {
    setPomodoroTime();
    return () => {
      resetPomodoro();
    };
  }, [resetPomodoro]);

  return (
    <>
      <Picker
        isOpen={pickerIsOpen}
        confirmed={confirmedTime}
        cancelled={cancelledPicker}
        columns={minutesList}
      />

      <IonToolbar>
        <IonButtons>
          <IonButton
            slot="start"
            color="secondary"
            onClick={() => {
              // calmSound.play();
              startPomodoro();
            }}
          >
            <IonIcon slot="icon-only" icon={playOutline} />
          </IonButton>
          <IonButton
            ref={pauseButtonEl}
            slot="end"
            color="secondary"
            onClick={() => {
              calmSound.pause();
              stopPomodoro();
            }}
          >
            <IonIcon slot="icon-only" icon={stopOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <div className={`${styles.time_container} ion-text-center`}>
        <svg className={styles.svg__circle}>
          <circle
            className={styles.svg__circle_element}
            cx="70"
            cy="70"
            r="70"
          ></circle>
          <circle
            className={`${styles.svg__circle_element} ${styles.svg__circle_element_progress}`}
            cx="70"
            cy="70"
            r="70"
            strokeDasharray={dashArrayStroke}
            strokeDashoffset={currentStroke}
          ></circle>
        </svg>
        <h2 className={styles.time_text} onClick={() => openPicker()}>
          {currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes} :{' '}
          {currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds}
        </h2>
      </div>
    </>
  );
});
