import React from 'react';
import ActionList from './actions/actionList';
import MyDayPlaylist from './design-my-day/my-day-plan';
import { TaskList } from './quests/taskList';
import RewardList from './rewards/rewardsList';

const Dashboard = () => {
  const [view, setView] = React.useState<'today' | 'config'>('today');
  return (
    <div id="dashboard">
      <div className="flex p-3 text-duck-green">
        <span
          className={`mx-4 my-2  cursor-pointer o px-4 py-2 bg-w9 rounded shadow text-lg font-mono font-semibold ${
            view === 'today' ? 'bg-dutch-orange' : ''
          }`}
          onClick={() => setView('today')}
        >
          See Today's plan
        </span>
        <span
          className={`mx-4 my-2  cursor-pointer o px-4 py-2 bg-w9 rounded shadow text-lg font-mono font-semibold ${
            view === 'config' ? 'bg-dutch-orange' : ''
          }`}
          onClick={() => setView('config')}
        >
          Manage Rewards and Tasks
        </span>
      </div>
      {view === 'today' ? (
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 lg:w-2/3 mx-4">
            <MyDayPlaylist />
          </div>
          <div className="md:w-1/2 lg:w-1/3 mr-4">
            <ActionList />
          </div>
        </div>
      ) : (
        <div className="flex justify-around">
          <TaskList />
          <RewardList />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
