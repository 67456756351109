import React from 'react';

import Dashboard from '../components/dashboard';

import { BasePage } from '../components/layout/base.page';

export const Home = () => {
  return (
    <BasePage>
      <Dashboard />
    </BasePage>
  );
};
