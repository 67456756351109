import { PickerColumn } from '@ionic/core';
export const minutesList: Array<PickerColumn> = [
  {
    name: 'minutes',
    selectedIndex: 5,
    options: [
      { text: '01', value: '1' },
      { text: '05', value: '5' },
      { text: '10', value: '10' },
      { text: '15', value: '15' },
      { text: '20', value: '20' },
      { text: '25', value: '25' },
      { text: '30', value: '30' },
      { text: '35', value: '35' },
      { text: '40', value: '40' },
      { text: '45', value: '45' },
      { text: '50', value: '50' },
      { text: '55', value: '55' },
      { text: '60', value: '60' },
    ],
  },
];
