import {
  useState,
  Fragment,
  KeyboardEvent,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {
  IonItem,
  IonInput,
  IonButton,
  IonChip,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import React from 'react';
import { cash, addCircleOutline, closeOutline } from 'ionicons/icons';
import {
  CreateTaskMutationVariables,
  CreateRewardMutationVariables,
} from '../API';
import { API, graphqlOperation, Hub } from 'aws-amplify';
import { createTask, createReward } from '../graphql/mutations';
import { Chanels, MutationEvents } from '../_store/index';
import { ErrorHandle } from '../utils/error.service';
export interface AddActivityProp {
  type: 'reward' | 'task';
}

export function AddActivity(props: AddActivityProp) {
  const [showInput, setShowInput] = useState(false);
  const [showCreditChip, setShowCreditChip] = useState(false);
  const [text, setText] = useState('');
  const [credit, setCredit] = useState(0);
  const inputEle = useRef<any>();
  const userProfileId = localStorage.getItem('pid') || '';

  const analyseText = useCallback((): string => {
    const creditRegex = /(for)?\s?\d+(\s?\w+(\s?for)?)?/i;
    const matchArr = text.match(creditRegex);
    const match = matchArr && matchArr[0];
    if (match) {
      const title = text.replace(match, '').trim();
      const numberArr = match.match(/\d+/);
      const number = numberArr && numberArr[0];
      if (number) {
        setCredit(+number);
        setShowCreditChip(true);
      }
      return title;
    }
    return text;
  }, [text]);
  useEffect(() => {
    analyseText();
  }, [analyseText]);

  useEffect(() => {
    // always focus the input when it shows up
    if (inputEle.current) {
      (inputEle.current?.childNodes[0] as HTMLInputElement)?.focus();
    }
  }, [showInput]);

  function dissmissCredit() {
    setShowCreditChip(false);
    setCredit(0);
  }

  function lookForEnter(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      addActivity();
    }
    if (e.key === 'Escape') {
      setShowInput(false);
    }
  }

  function getOperation() {
    const title = analyseText();
    if (props.type === 'task') {
      const input = {
        title,
        credit,
        type: 'default',
        user: userProfileId,
      };
      const variables: CreateTaskMutationVariables = {
        input,
      };
      return graphqlOperation(createTask, variables);
    }
    const input = {
      title,
      credit: -credit,
      user: userProfileId,
    };
    const variables: CreateRewardMutationVariables = {
      input,
    };
    return graphqlOperation(createReward, variables);
  }

  async function addActivity() {
    try {
      const operation = getOperation();
      await API.graphql(operation);
      setText('');
      setShowCreditChip(false);
      setCredit(0);
      Hub.dispatch(Chanels.Mutation, {
        event: MutationEvents.activityAdded,
        message: MutationEvents.activityAdded,
      });
    } catch (error) {
      ErrorHandle.handle(error);
    }
  }

  return (
    <IonItem lines="none">
      {showInput ? (
        <Fragment>
          <IonInput
            ref={inputEle}
            autofocus={true}
            autocapitalize="on"
            autoCorrect="on"
            debounce={200}
            onIonChange={(e: CustomEvent) => setText(e.detail.value)}
            onKeyUp={(e) => lookForEnter(e)}
            value={text}
            placeholder={`Add your ${props.type} here`}
          ></IonInput>
          {showCreditChip ? (
            <IonChip onClick={dissmissCredit}>
              <IonIcon icon={cash} color="primary"></IonIcon>
              <IonLabel>{credit}</IonLabel>
            </IonChip>
          ) : null}

          <IonButton color="primary" fill="clear" onClick={addActivity}>
            <IonIcon slot="icon-only" icon={addCircleOutline} />
          </IonButton>
          <IonButton
            color="medium"
            fill="clear"
            onClick={(e) => {
              setShowInput(false);
              setText('');
            }}
          >
            <IonIcon slot="icon-only" icon={closeOutline} />
          </IonButton>
        </Fragment>
      ) : (
        <IonButton expand="full" onClick={() => setShowInput(true)}>
          Add {props.type}
        </IonButton>
      )}
    </IonItem>
  );
}
