import React from 'react';
import { IonPicker } from '@ionic/react';
import { PickerColumn } from '@ionic/core';

export interface PickerProps {
  isOpen: boolean;
  confirmed: Function;
  cancelled: Function;
  columns: Array<PickerColumn>;
}

export const Picker: React.FC<PickerProps> = ({
  isOpen,
  confirmed,
  cancelled,
  columns,
}) => {
  return (
    <>
      <IonPicker
        isOpen={isOpen}
        columns={columns}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: (_) => {
              cancelled();
            },
          },
          {
            text: 'Confirm',
            handler: (value) => {
              confirmed(value);
            },
          },
        ]}
      ></IonPicker>
    </>
  );
};
