import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPopover,
  IonButtons,
  IonButton,
  getPlatforms,
} from '@ionic/react';
import React, { useState } from 'react';
import { UserProfile } from '../../models/index';
import { UserCard } from '../profile/user-card';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { menu, personSharp, book, keypadSharp } from 'ionicons/icons';

export interface ToolbarProp {
  title: string;
  collapse?: 'condense';
  profile: UserProfile;
}

export const Toolbar = (props: ToolbarProp) => {
  const { title, profile, collapse } = props;
  const isios = getPlatforms().includes('ios');

  function Account() {
    const [showPopover, setShowPopover] = useState<{
      open: boolean;
      event: Event | undefined;
    }>({ open: false, event: undefined });
    if (!profile || !!collapse) {
      return null;
    } else {
      return (
        <>
          <div
            slot="primary"
            className="cursor-pointer pr-1 text-2xl"
            onClick={(e) =>
              setShowPopover({ open: true, event: e.nativeEvent })
            }
          >
            <IonIcon icon={personSharp} />
          </div>
          <IonPopover
            isOpen={showPopover.open}
            event={showPopover.event}
            showBackdrop={false}
            onDidDismiss={() =>
              setShowPopover({ open: false, event: undefined })
            }
          >
            <UserCard profile={profile} />
          </IonPopover>
        </>
      );
    }
  }

  return (
    <IonHeader collapse={collapse}>
      <IonToolbar>
        <Link
          to="/"
          className={collapse === 'condense' ? 'hidden' : ''}
          slot="start"
        >
          <IonIcon className="text-3xl block md:hidden" icon={menu} />
          <img
            className="w-12 ml-2 hidden md:block"
            src="./assets/icon/icon.svg"
            alt="logo"
          />
        </Link>
        <IonTitle>
          <Link to="/">
            <span className={collapse !== 'condense' ? 'hidden md:inline' : ''}>
              {title}
            </span>
            <img
              className={
                (collapse === 'condense' ? 'hidden' : '') +
                ' w-12 m-auto md:hidden'
              }
              src="./assets/icon/icon.svg"
              alt="logo"
            />
          </Link>
        </IonTitle>
        <IonButtons slot="secondary" hidden={isios}>
          <IonButton routerLink="/log">
            <IonIcon slot="icon-only" icon={book} />
          </IonButton>
          <IonButton routerLink="/kitchen">
            <IonIcon slot="icon-only" icon={keypadSharp} />
          </IonButton>
        </IonButtons>
        <Account />
      </IonToolbar>
    </IonHeader>
  );
};
