import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import React from 'react';
import RewardForm from './rewardForm';
import { close } from 'ionicons/icons';
import { UpdateRewardMutationVariables } from '../../API';
import { API, graphqlOperation, Hub } from 'aws-amplify';
import { updateReward } from '../../graphql/mutations';
import { Chanels, MutationEvents } from '../../_store';
import { ErrorHandle } from '../../utils/error.service';

interface RewardModalProps {
  reward: any;
  showModalHandler: Function;
}

const RewardModal = (props: RewardModalProps) => {
  const { reward, showModalHandler } = props;

  const handleSubmit = async (data: any) => {
    const variables: UpdateRewardMutationVariables = {
      input: {
        id: reward && reward.id,
        ...data,
        credit: -+data.credit,
        type: data.type,
        timeCost: +data.timeCost,
        moneyCost: +data.moneyCost,
      },
    };
    try {
      await API.graphql(graphqlOperation(updateReward, variables));
      showModalHandler(false);
      Hub.dispatch(Chanels.Mutation, {
        event: MutationEvents.activityEdited,
        message: MutationEvents.activityEdited,
      });
    } catch (error) {
      ErrorHandle.handle(error);
    }
  };

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit a Reward</IonTitle>
          <IonButtons slot="primary">
            <IonButton color="medium" onClick={(e) => showModalHandler(false)}>
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <RewardForm {...reward} onSubmit={handleSubmit} />
    </IonContent>
  );
};

export default RewardModal;
