import React, { ReactNode } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { Toolbar } from './toolbar';
import { useHubState } from '../../_store/index';
import { HubSourceKey } from '../../services/profile.service';
export interface BasePageProp {
  title?: string;
  children?: ReactNode;
  notRefresh?: boolean;
}

export const BasePage = (props: BasePageProp) => {
  const title = props.title || 'Quests of Life';
  const [profile] = useHubState(HubSourceKey.profile);

  return (
    <IonPage>
      <Toolbar title={title} profile={profile} />
      <IonContent>
        <Toolbar title={title} collapse="condense" profile={profile} />
        {props.children}
      </IonContent>
    </IonPage>
  );
};
