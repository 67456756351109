import React from 'react';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButtons,
  IonButton,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { Pomodoro } from './pomodoro';
import { addAction } from '../../services/action.service';
import { SimpleActivityInput } from '../../API';

interface PomodoroModalProps {
  task: SimpleActivityInput;
  showModalHandler: Function;
}
export const PomodoroModal = (props: PomodoroModalProps) => {
  const { task, showModalHandler } = props;
  function closeModal(shouldRefresh = false) {
    showModalHandler(shouldRefresh);
  }

  async function timerEndsHandler(time: string) {
    await addAction('task', task, `session time: ${time} min`);
    closeModal(true);
  }

  return (
    <>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Set time to tackle {task.title}</IonTitle>
            <IonButtons slot="primary">
              <IonButton color="medium" onClick={() => closeModal()}>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <Pomodoro timerEnded={timerEndsHandler} />
      </IonContent>
    </>
  );
};
