import React from 'react';
import { BasePage } from '../../components/layout/base.page';
import ActionList from '../../components/actions/actionList';

export const ActionPage = () => {
  return (
    <BasePage title="Actions">
      <ActionList />
    </BasePage>
  );
};
