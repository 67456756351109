import { IonLabel, IonIcon, IonText } from '@ionic/react';
import React from 'react';
import {
  checkmarkDoneCircle,
  checkmarkCircle,
  shieldCheckmark,
} from 'ionicons/icons';
import moment from 'moment';
import { TaskTypes } from '../../models/enums';

const iconMap = {
  [TaskTypes.default]: [checkmarkDoneCircle, 'primary'],
  [TaskTypes.once_daily]: [checkmarkCircle, 'secondary'],
  [TaskTypes.oneoff]: [shieldCheckmark, 'tertiary'],
};

export function isDailyTaskFinishedToday(task: any): boolean {
  // daily task that is finished
  if (task.type === TaskTypes.once_daily && task.finished) {
    // finished date is today
    return moment().isSame(task.finished, 'day');
  }
  return false;
}

export function ItemDisplay(task: any) {
  return (
    <>
      <IonLabel
        style={
          isDailyTaskFinishedToday(task)
            ? { textDecoration: 'line-through' }
            : {}
        }
      >
        <IonIcon color={iconMap[task.type][1]} icon={iconMap[task.type][0]} />{' '}
        {task.title}
      </IonLabel>
      <IonText color="primary" slot="end">
        {task.credit}💰
      </IonText>
    </>
  );
}
