import React, { ComponentType } from 'react';
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonBadge,
  IonRouterOutlet,
} from '@ionic/react';
import { Route, Redirect } from 'react-router-dom';
import { fileTrayStacked, cart, receipt, moon, sunny } from 'ionicons/icons';
import { QuestPage } from '../../pages/mobile/quests';
import { RewardPage } from '../../pages/mobile/rewards';
import { ActionPage } from '../../pages/mobile/actions';
import { WeekViewPage } from '../../pages/mobile/weekView';
import { MyDayPage } from '../../pages/mobile/my-day';

export interface TabButtonProp {
  tabName: string;
  icon?: string;
  label?: string;
  href: string;
  badge?: number;
  component: ComponentType;
}

const tabData: TabButtonProp[] = [
  {
    tabName: 'dayplan',
    icon: sunny,
    component: MyDayPage,
    label: 'Day Plan',
    href: '/dayplan',
  },
  {
    tabName: 'quest',
    icon: fileTrayStacked,
    label: 'Quest',
    component: QuestPage,
    href: '/quests',
  },
  {
    tabName: 'reward',
    icon: cart,
    component: RewardPage,
    label: 'Reward',
    href: '/rewards',
  },
  {
    tabName: 'action',
    icon: receipt,
    component: ActionPage,
    label: 'Action',
    href: '/actions',
  },
  {
    tabName: 'reflect',
    icon: moon,
    component: WeekViewPage,
    label: 'Reflect',
    href: '/reflect',
  },
];

export const Tabs = () => {
  const buttons = tabData.map((tab, index) => (
    <IonTabButton
      tabIndex={index}
      key={index}
      tab={tab.tabName}
      href={tab.href}
    >
      <IonIcon hidden={!tab.icon} icon={tab.icon} />
      <IonLabel>{tab.label}</IonLabel>
      <IonBadge hidden={!tab.badge}>{tab.badge}</IonBadge>
    </IonTabButton>
  ));

  const routes = tabData.map((tab, i) => (
    <Route exact key={i} path={tab.href} component={tab.component} />
  ));
  return (
    <IonTabs>
      <IonRouterOutlet>
        {routes}
        <Redirect exact from="/" to="/dayplan" />
        <Redirect exact from="/home" to="/dayplan" />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">{buttons}</IonTabBar>
    </IonTabs>
  );
};
