import React from 'react';

export function KitchenSink() {
  return (
    <>
      {/* https://tailwindcss.com/docs/
      https://tailwindcomponents.com/cheatsheet/ */}
      <div className="m-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2">
        <div className="bg-gray-300">1</div>
        <div className="bg-gray-300">1</div>
        <div className="bg-gray-300">1</div>
        <div className="bg-gray-300">1</div>
        <div className="bg-gray-300">1</div>
        <div className="bg-gray-300">1</div>
        <div className="bg-gray-300">1</div>
        <div className="bg-gray-300">1</div>
        <div className="bg-gray-300">1</div>
      </div>

      <div className="md:flex m-6">
        <div className="md:flex-shrink-0">
          <img
            className="rounded-lg md:w-56"
            src="https://images.unsplash.com/photo-1556740738-b6a63e27c4df?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=448&q=80"
            alt="Woman paying for a purchase"
          />
        </div>
        <div className="mt-4 md:mt-0 md:ml-6">
          <div className="uppercase tracking-wide text-sm text-indigo-600 font-bold">
            Marketing
          </div>

          <p className="mt-2 text-gray-600">
            Getting a new business off the ground is a lot of hard work. Here
            are five ideas you can use to find your first customers.
          </p>
        </div>
      </div>
    </>
  );
}
