export const schema: any = {
  models: {
    User: {
      name: 'User',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        cid: {
          name: 'cid',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        username: {
          name: 'username',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        timeZoneOffset: {
          name: 'timeZoneOffset',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        credits: {
          name: 'credits',
          isArray: false,
          type: 'Float',
          isRequired: true,
          attributes: [],
        },
        points: {
          name: 'points',
          isArray: false,
          type: 'Float',
          isRequired: true,
          attributes: [],
        },
        dayPlan: {
          name: 'dayPlan',
          isArray: false,
          type: {
            nonModel: 'DayPlan',
          },
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        actions: {
          name: 'actions',
          isArray: true,
          type: {
            model: 'Action',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'user',
          },
        },
        tasks: {
          name: 'tasks',
          isArray: true,
          type: {
            model: 'Task',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'user',
          },
        },
        rewards: {
          name: 'rewards',
          isArray: true,
          type: {
            model: 'Reward',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'user',
          },
        },
      },
      syncable: true,
      pluralName: 'Users',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byCid',
            fields: ['cid'],
            queryField: 'profileByCid',
          },
        },
        {
          type: 'key',
          properties: {
            name: 'username',
            fields: ['username'],
            queryField: 'profileByUsername',
          },
        },
      ],
    },
    Action: {
      name: 'Action',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        activityId: {
          name: 'activityId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        user: {
          name: 'user',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        note: {
          name: 'note',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        credit: {
          name: 'credit',
          isArray: false,
          type: 'Float',
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Actions',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byUserByDate',
            fields: ['user', 'createdAt'],
          },
        },
      ],
    },
    Task: {
      name: 'Task',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        user: {
          name: 'user',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        type: {
          name: 'type',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        credit: {
          name: 'credit',
          isArray: false,
          type: 'Float',
          isRequired: true,
          attributes: [],
        },
        finished: {
          name: 'finished',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Tasks',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byUser',
            fields: ['user', 'title'],
          },
        },
      ],
    },
    Reward: {
      name: 'Reward',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        user: {
          name: 'user',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        credit: {
          name: 'credit',
          isArray: false,
          type: 'Float',
          isRequired: true,
          attributes: [],
        },
        type: {
          name: 'type',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        timeCost: {
          name: 'timeCost',
          isArray: false,
          type: 'Float',
          isRequired: false,
          attributes: [],
        },
        moneyCost: {
          name: 'moneyCost',
          isArray: false,
          type: 'Float',
          isRequired: false,
          attributes: [],
        },
        isOneOff: {
          name: 'isOneOff',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        finished: {
          name: 'finished',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Rewards',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byUser',
            fields: ['user', 'title'],
          },
        },
      ],
    },
  },
  enums: {},
  nonModels: {
    DayPlan: {
      name: 'DayPlan',
      fields: {
        habits: {
          name: 'habits',
          isArray: true,
          type: {
            nonModel: 'SimpleActivity',
          },
          isRequired: false,
          attributes: [],
        },
        main: {
          name: 'main',
          isArray: true,
          type: {
            nonModel: 'SimpleActivity',
          },
          isRequired: false,
          attributes: [],
        },
      },
    },
    SimpleActivity: {
      name: 'SimpleActivity',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        type: {
          name: 'type',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        credit: {
          name: 'credit',
          isArray: false,
          type: 'Float',
          isRequired: true,
          attributes: [],
        },
        finished: {
          name: 'finished',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
      },
    },
  },
  version: '0a15d536225a81406388c1ca2ee606c7',
};
