import React, { ReactChild } from 'react';
import { TaskTypes } from '../../models';
import moment from 'moment';
import { IonIcon } from '@ionic/react';
import { checkmarkDone } from 'ionicons/icons';
import { TaskModel } from '../../models/typing';

interface DetailedTaskModel extends TaskModel {
  isQuestComplete: boolean;
  doneTimes: number;
}

interface IProps {
  quest: DetailedTaskModel;
  children: ReactChild;
  className: string;
}
export default function QuestCard(props: IProps) {
  const { quest, children, className } = props;
  return (
    <div
      className={`${className} bg-orange-100 shadow-lg rounded-lg py-2 text-a-w24`}
    >
      <div className="px-4 py-2">
        <div className="flex justify-between align-middle text-base sm:text-sm font-semibold">
          <span>{quest.title}</span>
          <span className="text-a-w53 pl-3">+{quest.credit}</span>
        </div>
        <div>
          <span
            hidden={quest.type === TaskTypes.once_daily}
            className="text-xs text-white bg-a-w76 px-3 py-px rounded-full
          shadow font-semibold inline-block"
          >
            x {quest.doneTimes}
          </span>
        </div>
      </div>
      <p className="px-4 text-sm md:text-xs truncate">{quest.description}</p>
      <div className="my-2 px-4 flex justify-between md:text-xs">
        <div className="block"></div>
        <div className="">{children}</div>
        <div hidden={!quest.isQuestComplete} className="text-a-w24 py-1">
          <span>Done on:</span>
          {moment(quest.finished).format('LT')}
          <IonIcon className="ml-3" icon={checkmarkDone} />
        </div>
      </div>
    </div>
  );
}
