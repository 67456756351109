import {
  IonItem,
  IonIcon,
  IonLabel,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonNote,
} from '@ionic/react';
import React from 'react';
import { trashBin } from 'ionicons/icons';

import { ActionModel } from '../../models/index';
import { removeAction } from '../../services/action.service';
import { HubSourceKey } from '../../services/profile.service';
import { useHubState } from '../../_store/index';

const ActionList = () => {
  const [actions] = useHubState(HubSourceKey.actions);

  const questActions = actions?.filter(
    (action: ActionModel) => action?.credit && action?.credit > 0
  );
  const rewardActions = actions?.filter(
    (action: ActionModel) => action?.credit && action?.credit < 0
  );

  const items = actions?.map((action: ActionModel, i: number) => (
    <IonItemSliding key={i}>
      <IonItem>
        <IonLabel>
          {action.description} <IonNote>{action.note}</IonNote>
        </IonLabel>
        <span slot="start">
          {new Intl.DateTimeFormat('en-GB', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(new Date(action.createdAt))}
        </span>
        <span slot="end">
          {action.credit > 0 ? (
            <span style={{ color: 'green' }}>+{action.credit}</span>
          ) : (
            <span>{-action.credit}</span>
          )}
        </span>
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption color="danger" onClick={() => removeAction(action)}>
          <IonIcon slot="icon-only" icon={trashBin}></IonIcon>
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  ));
  return (
    <div>
      <h3 className="text-lg font-semibold px-4">Actions today earned</h3>
      <p className="mx-3">
        <span color="success">
          <span className="pr-3">
            <span role="img" aria-label="earned">
              💰
            </span>
            +
            {questActions?.reduce(
              (prev: number, b: ActionModel) => prev + b?.credit,
              0
            )}
          </span>
        </span>
        <span color="dark">
          <span>
            <span role="img" aria-label="spent">
              🛍️
            </span>{' '}
            {
              -rewardActions?.reduce(
                (prev: number, b: any) => prev + b.credit,
                0
              )
            }
          </span>
        </span>
      </p>

      <IonList>{items}</IonList>
    </div>
  );
};

export default ActionList;
