import { BasePage } from '../components/layout/base.page';
import { KitchenSink } from '../components/layout/tailwind-kitchensink';
import React from 'react';

export function Catalog() {
  return (
    <BasePage>
      <KitchenSink />
    </BasePage>
  );
}
