import { TaskModel, RewardModel, ActionModel } from '../models/index';
import {
  UpdateTaskMutationVariables,
  CreateActionInput,
  CreateActionMutationVariables,
  UpdateRewardMutationVariables,
  UpdateUserMutationVariables,
  DeleteActionMutationVariables,
} from '../API';
import {
  createAction,
  updateReward,
  updateUser,
  deleteAction,
} from '../graphql/mutations';
import { API, graphqlOperation, Hub } from 'aws-amplify';
import { updateTask } from '../graphql/mutations';
import { ProfileService } from './profile.service';
import { Chanels, MutationEvents } from '../_store/index';
import gql from 'graphql-tag';
import { ProfileQueryVariables, ProfileResponse } from '../models';
import { thisWeekRange } from '../utils/timehelper';
import { TaskTypes } from '../models/enums';
import { ErrorHandle } from '../utils/error.service';
import { SimpleActivityInput } from '../API';
const getProfile = ProfileService.getProfile;

export async function updateScore(
  action: CreateActionInput,
  revertAction = false
) {
  let points = getProfile().points;
  let credits = getProfile().credits;
  if (revertAction) {
    // change credits back
    credits -= action.credit;
    if (action.credit > 0) {
      // revert a task completion change points too
      points -= action.credit;
    }
  } else {
    credits += action.credit;
    if (action.credit > 0) {
      points += action.credit;
    }
  }
  const updateScoreVars: UpdateUserMutationVariables = {
    input: {
      id: getProfile().id,
      points,
      credits,
    },
  };
  try {
    return await API.graphql(graphqlOperation(updateUser, updateScoreVars));
  } catch (error) {
    ErrorHandle.handle(error);
  }
}

export async function addAction(
  type: 'reward' | 'task',
  activity: TaskModel | RewardModel | SimpleActivityInput,
  note = ''
) {
  try {
    const variables: CreateActionMutationVariables = {
      input: {
        user: getProfile().id,
        activityId: activity.id,
        description: activity.title,
        credit: activity.credit,
        note,
      },
    };
    await API.graphql(graphqlOperation(createAction, variables));
    await updateScore(variables.input);
    if (type === 'reward' && (activity as RewardModel).isOneOff) {
      const variables2: UpdateRewardMutationVariables = {
        input: {
          id: activity.id,
          finished: new Date().toISOString(),
        },
      };
      await API.graphql(graphqlOperation(updateReward, variables2));
    } else if (
      (activity as TaskModel).type === TaskTypes.oneoff ||
      (activity as TaskModel).type === TaskTypes.once_daily
    ) {
      const variables3: UpdateTaskMutationVariables = {
        input: {
          id: activity.id,
          finished: new Date().toISOString(),
        },
      };
      await API.graphql(graphqlOperation(updateTask, variables3));
    }
    Hub.dispatch(Chanels.Mutation, {
      event: MutationEvents.actionCreated,
      message: MutationEvents.actionCreated,
    });
  } catch (error) {
    ErrorHandle.handle(error);
  }
}

export async function removeAction(action: ActionModel) {
  try {
    const variables: DeleteActionMutationVariables = {
      input: { id: action.id },
    };
    await API.graphql(graphqlOperation(deleteAction, variables));
    await updateScore(action, true);
    Hub.dispatch(Chanels.Mutation, {
      message: MutationEvents.actionDeleted,
      event: MutationEvents.actionDeleted,
    });
  } catch (error) {
    console.log(error);
  }
}

export async function fetchThisWeeksTasks(): Promise<ActionModel[] | void> {
  try {
    const variables: ProfileQueryVariables = {
      id: localStorage.getItem('pid') || '',
      start: thisWeekRange[0],
      end: thisWeekRange[1],
    };
    const res: any = await API.graphql(
      graphqlOperation(actionsThisWeekQuery, variables)
    );
    return (res.data.getUser as ProfileResponse).actions.items;
  } catch (error) {
    ErrorHandle.handle(error);
  }
}

export const actionsThisWeekQuery = gql`
  query profile($id: ID!, $start: String!, $end: String!) {
    getUser(id: $id) {
      actions(createdAt: { between: [$start, $end] }) {
        items {
          id
          description
          note
          createdAt
          credit
        }
      }
    }
  }
`;
