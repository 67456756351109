import { addAction } from '../../services/action.service';
import {
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonIcon,
  IonButton,
  IonItem,
} from '@ionic/react';
import React from 'react';
import { isDailyTaskFinishedToday, ItemDisplay } from './taskItemDisplay';
import { createOutline, closeOutline, stopwatchOutline } from 'ionicons/icons';
import { deleteTask } from '../../graphql/mutations';
import { graphqlOperation, API, Hub } from 'aws-amplify';
import { Chanels, MutationEvents } from '../../_store';
import { TaskTypes } from '../../models/enums';
import { ErrorHandle } from '../../utils/error.service';
import { SimpleActivityInput } from '../../API';

export interface SlidingItemProp {
  i: number;
  task: SimpleActivityInput;
  setSelectedTask: Function;
  setShowModal: Function;
  setShowNoteInput: Function;
  setShowPomodoro: Function;
}

export function SlidingItem(props: SlidingItemProp) {
  const { task } = props;

  async function complete() {
    await addAction('task', task);
  }

  async function removeTask() {
    try {
      await API.graphql(
        graphqlOperation(deleteTask, {
          input: {
            id: task.id,
          },
        })
      );
      Hub.dispatch(Chanels.Mutation, {
        event: MutationEvents.activityDeleted,
        message: MutationEvents.activityDeleted,
      });
    } catch (error) {
      ErrorHandle.handle(error);
    }
  }

  function showEditTaskModel(): void {
    props.setShowModal(true);
    props.setSelectedTask(task);
  }

  async function longSwipeToEnterNote() {
    props.setShowModal(true);
    props.setSelectedTask(task);
    props.setShowNoteInput(true);
  }

  async function showPomodoroModel() {
    props.setShowModal(true);
    props.setSelectedTask(task);
    props.setShowPomodoro(true);
  }

  return (
    <IonItemSliding key={props.i} disabled={isDailyTaskFinishedToday(task)}>
      <IonItem>
        {ItemDisplay(task)}
        {task.type === TaskTypes.default ? (
          <IonButton color="success" onClick={() => showPomodoroModel()}>
            <IonIcon slot="icon-only" icon={stopwatchOutline} />
          </IonButton>
        ) : (
          ''
        )}
      </IonItem>

      <IonItemOptions side="end">
        <IonItemOption
          onClick={() => {
            showEditTaskModel();
          }}
        >
          <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
        </IonItemOption>
        <IonItemOption color="danger" onClick={removeTask}>
          <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
        </IonItemOption>
      </IonItemOptions>
      <IonItemOptions side="start" onIonSwipe={longSwipeToEnterNote}>
        <IonItemOption expandable onClick={complete}>
          Complete
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
}
