import React, { useEffect, useState } from 'react';
import { BasePage } from '../../components/layout/base.page';
import { fetchThisWeeksTasks } from '../../services/action.service';
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  IonLoading,
} from '@ionic/react';
import { calendar, barbell, caretDown, caretForward } from 'ionicons/icons';
import moment from 'moment';
import { sumPosNumArr } from '../../utils/my-dash';
import { ActionModel } from '../../models/index';
import { VerticalTimeline } from '../../components/actions/verticalTimeLine';

const groupBy = function <T>(
  xs: T[],
  key: string
): { [description: string]: T[] } {
  return xs?.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

interface ActivityDetailProps {
  activity: string;
  actionsGrouped: any;
}

function ActivityDetail(props: ActivityDetailProps) {
  const { activity, actionsGrouped } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div className="cursor-pointer" onClick={(e) => setExpanded(!expanded)}>
        <IonIcon
          className="text-blue-600 pr-3"
          icon={expanded ? caretDown : caretForward}
        />
        <span className="text-grey-600">{activity}</span>

        <b className="float-right  text-blue-600">
          {actionsGrouped[activity].length}
        </b>
      </div>
      {expanded ? (
        <div className="mt-3">
          {actionsGrouped[activity]?.map((action: ActionModel, i: number) => (
            <div key={i} className="mt-2">
              <p>
                {action.note ? (
                  <>
                    <span className="pr-2" role="img" aria-label="note">
                      📔
                    </span>
                    <span className="text-gray-600">{action.note}</span>
                  </>
                ) : (
                  <>
                    <span className="pr-2" role="img" aria-label="note">
                      📔
                    </span>
                    <span className="text-gray-600">You did it</span>
                  </>
                )}
                <span className="text-gray-600 float-right">
                  {moment(action.createdAt).fromNow()}
                </span>
              </p>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

interface GroupedActions {
  actions: ActionModel[];
}

function GroupedActions(props: GroupedActions) {
  const { actions } = props;
  if (!actions) return null;
  const actionsGrouped = groupBy(actions, 'description');
  const activities = Object.keys(actionsGrouped);
  // console.log(actions);
  // console.log(actionsGrouped);

  return (
    <div className="mx-5 md:m-auto max-w-xl">
      <h3 className="my-5 text-lg">
        Points earned total:
        <span className="float-right">
          {' '}
          💰{sumPosNumArr(actions, 'credit')}
        </span>
      </h3>
      {activities
        ?.sort((a, b) => actionsGrouped[b].length - actionsGrouped[a].length)
        .map((activity, i) => {
          return (
            <div key={i} className="my-2">
              <ActivityDetail
                actionsGrouped={actionsGrouped}
                activity={activity}
              />
            </div>
          );
        })}
    </div>
  );
}
export const WeekViewPage = () => {
  const [actions, setActions] = useState<ActionModel[]>();
  const [view, setView] = useState<string>('timeline');

  useEffect(() => {
    let mounted = true;
    fetchThisWeeksTasks().then((actions) => {
      if (mounted && actions) {
        setActions(actions);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);
  return (
    <BasePage title="This week" notRefresh={true}>
      <IonSegment
        className="my-2"
        value={view}
        onIonChange={(e: any) => setView(e.detail.value)}
      >
        <IonSegmentButton value="activity" layout="icon-start">
          <IonLabel>Activity</IonLabel>
          <IonIcon icon={barbell} />
        </IonSegmentButton>
        <IonSegmentButton value="timeline" layout="icon-start">
          <IonLabel>History</IonLabel>
          <IonIcon icon={calendar} />
        </IonSegmentButton>
      </IonSegment>
      {!actions ? (
        <IonLoading isOpen={!actions}></IonLoading>
      ) : view === 'activity' ? (
        <GroupedActions actions={actions} />
      ) : (
        <VerticalTimeline items={actions} />
      )}
    </BasePage>
  );
};
