import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonButton,
  IonContent,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonHeader,
  IonTitle,
  IonList,
} from '@ionic/react';

import { UserProfile } from '../models';
import { ProfileList } from '../components/profile/profile-list';
import { TrophyListItem } from '../components/trophy-list-item';
import moment from 'moment';
import { AllyListItem } from '../components/ally-list-item';

export const Profile: React.FC = React.memo(() => {
  const [profile, setProfile] = useState<UserProfile | null>();

  const allies = [
    {
      id: '1',
      name: 'Filly the Warrior',
      dateAllied: '2020-06-18T11:11:11.131Z',
      online: false,
    },
    {
      id: '2',
      name: 'Trilly the Rogue',
      dateAllied: '2020-06-13T13:32:47.110Z',
      online: true,
    },
  ];

  const testTrophyItems = [
    {
      id: '0',
      name: 'Completed 10 quests',
      imageSrc:
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAAAAACH5BAAAAAAALAAAAAABAAEAAAICTAEAOw==',
      dateAcquired: '2020-06-18T14:44:54.267Z',
    },
    {
      id: '1',
      name: 'Completed 5 quests',
      imageSrc:
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAAAAACH5BAAAAAAALAAAAAABAAEAAAICTAEAOw==',
      dateAcquired: '2020-06-17T07:01:36.832Z',
    },
    {
      id: '2',
      name: 'Completed 1st quest',
      imageSrc:
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAAAAACH5BAAAAAAALAAAAAABAAEAAAICTAEAOw==',
      dateAcquired: '2020-06-16T13:10:22.332Z',
    },
    {
      id: '3',
      name: 'Completed rite of passage',
      imageSrc:
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAAAAACH5BAAAAAAALAAAAAABAAEAAAICTAEAOw==',
      dateAcquired: '2020-06-15T15:22:04.012Z',
    },
  ];

  const isSameDay = (
    dateToCheck: moment.Moment,
    momentDate: moment.Moment
  ): boolean => {
    return dateToCheck.isSame(momentDate, 'd');
  };

  const makeDateString = (dateString: string): string => {
    const referenceDate = moment();
    const dateToCheck = moment(dateString);

    const today = referenceDate.clone().startOf('day');
    const yesterday = referenceDate.clone().subtract(1, 'days').startOf('day');

    return isSameDay(dateToCheck, today)
      ? 'Today'
      : isSameDay(dateToCheck, yesterday)
      ? 'Yesterday'
      : moment(dateString).format('MMMM Do, YYYY');
  };

  useEffect(() => {
    const localProfile = JSON.parse(localStorage.getItem('profile') || '');

    setProfile(localProfile);
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className="bg-gray-300">
          <h1 className="flex justify-between mt-0 p-4">
            <IonBackButton defaultHref="/" />
            {profile?.username}{' '}
            <span className="text-base">
              <IonButton size="small">edit</IonButton>
            </span>
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-1 md:gap-6 p-2">
          <div className="md:border rounded-sm pt-2 pb-6 pr-2">
            {!!profile ? (
              <ProfileList
                class="Mage"
                createdAt={profile.createdAt}
                level={1}
                points={profile.points}
                rank="Apprentice"
              />
            ) : (
              ''
            )}
          </div>

          <div className="col-span-2 md:border rounded-sm p-2 pb-6">
            <IonHeader>
              <IonToolbar>
                <IonTitle></IonTitle>
                <IonButtons slot="secondary">
                  <IonButton fill="solid">Spend XP</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>

            <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-1 md:gap-4 p-2">
              <div>
                <h2>Trophies</h2>
                <IonList>
                  {testTrophyItems.map((trophy) => (
                    <TrophyListItem
                      key={trophy.id}
                      dateAcquired={makeDateString(trophy.dateAcquired)}
                      imageSrc={trophy.imageSrc}
                      name={trophy.name}
                    />
                  ))}
                </IonList>
              </div>
              <div>
                <h2>Allies</h2>
                <IonList>
                  {allies.map((ally) => (
                    <AllyListItem
                      key={ally.id}
                      name={ally.name}
                      dateAllied={makeDateString(ally.dateAllied)}
                      online={ally.online}
                    />
                  ))}
                </IonList>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
});
