import React from 'react';
import { BasePage } from '../../components/layout/base.page';
import MyDayPlaylist from '../../components/design-my-day/my-day-plan';

export const MyDayPage = () => {
  return (
    <BasePage title="Quests">
      <MyDayPlaylist />
    </BasePage>
  );
};
