/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cid
      username
      timeZoneOffset
      credits
      points
      dayPlan {
        habits {
          id
          type
          title
          description
          credit
          finished
        }
        main {
          id
          type
          title
          description
          credit
          finished
        }
      }
      createdAt
      updatedAt
      actions {
        items {
          id
          activityId
          user
          description
          note
          credit
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          user
          type
          title
          description
          credit
          finished
          createdAt
          updatedAt
        }
        nextToken
      }
      rewards {
        items {
          id
          user
          title
          description
          credit
          type
          timeCost
          moneyCost
          isOneOff
          finished
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cid
      username
      timeZoneOffset
      credits
      points
      dayPlan {
        habits {
          id
          type
          title
          description
          credit
          finished
        }
        main {
          id
          type
          title
          description
          credit
          finished
        }
      }
      createdAt
      updatedAt
      actions {
        items {
          id
          activityId
          user
          description
          note
          credit
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          user
          type
          title
          description
          credit
          finished
          createdAt
          updatedAt
        }
        nextToken
      }
      rewards {
        items {
          id
          user
          title
          description
          credit
          type
          timeCost
          moneyCost
          isOneOff
          finished
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cid
      username
      timeZoneOffset
      credits
      points
      dayPlan {
        habits {
          id
          type
          title
          description
          credit
          finished
        }
        main {
          id
          type
          title
          description
          credit
          finished
        }
      }
      createdAt
      updatedAt
      actions {
        items {
          id
          activityId
          user
          description
          note
          credit
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          user
          type
          title
          description
          credit
          finished
          createdAt
          updatedAt
        }
        nextToken
      }
      rewards {
        items {
          id
          user
          title
          description
          credit
          type
          timeCost
          moneyCost
          isOneOff
          finished
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      user
      type
      title
      description
      credit
      finished
      createdAt
      updatedAt
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      user
      type
      title
      description
      credit
      finished
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      user
      type
      title
      description
      credit
      finished
      createdAt
      updatedAt
    }
  }
`;
export const createReward = /* GraphQL */ `
  mutation CreateReward(
    $input: CreateRewardInput!
    $condition: ModelRewardConditionInput
  ) {
    createReward(input: $input, condition: $condition) {
      id
      user
      title
      description
      credit
      type
      timeCost
      moneyCost
      isOneOff
      finished
      createdAt
      updatedAt
    }
  }
`;
export const updateReward = /* GraphQL */ `
  mutation UpdateReward(
    $input: UpdateRewardInput!
    $condition: ModelRewardConditionInput
  ) {
    updateReward(input: $input, condition: $condition) {
      id
      user
      title
      description
      credit
      type
      timeCost
      moneyCost
      isOneOff
      finished
      createdAt
      updatedAt
    }
  }
`;
export const deleteReward = /* GraphQL */ `
  mutation DeleteReward(
    $input: DeleteRewardInput!
    $condition: ModelRewardConditionInput
  ) {
    deleteReward(input: $input, condition: $condition) {
      id
      user
      title
      description
      credit
      type
      timeCost
      moneyCost
      isOneOff
      finished
      createdAt
      updatedAt
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      activityId
      user
      description
      note
      credit
      createdAt
      updatedAt
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      activityId
      user
      description
      note
      credit
      createdAt
      updatedAt
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      activityId
      user
      description
      note
      credit
      createdAt
      updatedAt
    }
  }
`;
