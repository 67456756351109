/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://f6sm2gvvkfhcpflrtudg4l43ze.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ukckkvjiejgx3jixhungyh2g3q",
    "aws_cognito_identity_pool_id": "eu-west-2:49b4f9c8-befb-4b88-9730-edcb4ca10ffd",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_bKL5O7Vxg",
    "aws_user_pools_web_client_id": "17vpmcm17diq6fmv6vo82190so",
    "oauth": {},
    "aws_mobile_analytics_app_id": "d2421e784b4744baaac943edaa81da06",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
