import { GraphQLError, HubCallback } from '../models/typing';
import { Hub } from 'aws-amplify';
import { Chanels, AppEvents } from '../_store/index';
export class ErrorHandle {
  static handle(errs: GraphQLError[] | undefined) {
    if (errs === undefined) {
      return;
    } else {
      Hub.dispatch(Chanels.Error, {
        event: AppEvents.gqlErrorRecieved,
        message: AppEvents.gqlErrorRecieved,
        data: errs,
      });
      return errs[0].message;
    }
  }
  static listenToError(listener: HubCallback) {
    Hub.listen(Chanels.Error, listener);
  }
  static removeErrorListener(listener: HubCallback) {
    Hub.remove(Chanels.Error, listener);
  }
}
