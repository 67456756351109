import { useState } from 'react';
import {
  IonButton,
  IonItem,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonInput,
  IonButtons,
  IonIcon,
} from '@ionic/react';
import React, { KeyboardEvent } from 'react';
import { close } from 'ionicons/icons';
import { addAction } from '../services/action.service';

export interface ActivityNoteItemProp {
  type: 'reward' | 'task';
  activity: any;
  finishAddCallback: Function;
}

export function ActivityNoteItem(props: ActivityNoteItemProp) {
  const { type, activity, finishAddCallback } = props;
  const [note, setNote] = useState('');

  function getActionName(type: string): string {
    return type === 'reward' ? 'claim' : 'complete';
  }

  function lookForEnter(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      addActionAndUpdateState();
    }
  }

  async function addActionAndUpdateState() {
    await addAction(type, activity, note);
    finishAddCallback(true);
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Add Note to {getActionName(type)} {type}
          </IonTitle>
          <IonButtons slot="primary">
            <IonButton color="medium" onClick={(e) => finishAddCallback(false)}>
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="p-3">
        <IonItem>
          <IonInput
            autofocus={true}
            autocapitalize="on"
            debounce={200}
            inputmode="text"
            onKeyUp={(e) => lookForEnter(e)}
            onIonChange={(e: CustomEvent) => setNote(e.detail.value)}
            value={note}
            placeholder={`Add your note here`}
          ></IonInput>
        </IonItem>
        <IonItem className="text-center" lines="none">
          <IonButton onClick={addActionAndUpdateState}>
            Add note and {getActionName(type)}
          </IonButton>
        </IonItem>
      </IonContent>
    </>
  );
}
