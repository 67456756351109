import { Hub, Cache } from 'aws-amplify';
import { ProfileService, HubSourceKey } from '../services/profile.service';
import { HubCapsule } from '../models/typing';
import { useState, useEffect } from 'react';

export enum Chanels {
  User = 'User',
  Mutation = 'Mutation',
  Error = 'Error',
}

export enum AppEvents {
  gqlErrorRecieved = 'gqlErrorRecieved',
}
export enum UserEvents {
  profileLoaded = 'profileLoaded',
}
export enum MutationEvents {
  actionCreated = 'actionCreated',
  actionDeleted = 'actionDeleted',
  activityAdded = 'activityAdded',
  activityDeleted = 'activityAdded',
  activityEdited = 'activityEdited',
  dayPlanUpdated = 'dayPlanUpdated',
}

async function initState(cid: string) {
  Cache.setItem('cid', cid);
  await ProfileService.fetchProfile(cid);
  Hub.dispatch(Chanels.User, {
    event: UserEvents.profileLoaded,
    message: UserEvents.profileLoaded,
  });
}

Hub.listen(/.*/, (data) => {
  switch (data.payload.message) {
    case 'signedin':
      const cid = data.payload.data.username;
      initState(cid);
      break;
    case MutationEvents.actionCreated:
    case MutationEvents.actionDeleted:
    case MutationEvents.activityAdded:
    case MutationEvents.activityEdited:
    case MutationEvents.activityDeleted:
    case MutationEvents.dayPlanUpdated:
      ProfileService.fetchFull().then(() => {
        Hub.dispatch(Chanels.User, {
          event: UserEvents.profileLoaded,
          message: UserEvents.profileLoaded,
        });
      });
      document?.querySelector('ion-item-sliding')?.closeOpened();
      break;
    default:
      break;
  }
});

// This custom hook use Hub event system.
export function useHubState(key: HubSourceKey) {
  const [state, setState] = useState(() =>
    JSON.parse(localStorage.getItem(key) || '[]')
  );
  useEffect(() => {
    const listener = (data: HubCapsule) => {
      if (data.payload.message === UserEvents.profileLoaded) {
        setState(JSON.parse(localStorage.getItem(key) || '[]'));
      }
    };
    Hub.listen(Chanels.User, listener);
    return () => {
      Hub.remove(Chanels.User, listener);
    };
  }, [key]);

  return [state];
}

export function useLocalStorageState(key: string, defaultValue = '') {
  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    window.localStorage.setItem(key, state);
  }, [key, state]);

  return [state, setState];
}
