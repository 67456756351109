import React from 'react';
import { close } from 'ionicons/icons';
import {
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';

interface IProps {
  children: JSX.Element;
  handleDismissModal: Function;
  showModal: boolean;
  title: string;
}

export default function BaseModal(props: IProps) {
  const { showModal, handleDismissModal, children, title } = props;
  return (
    <IonModal
      swipeToClose={true}
      isOpen={showModal}
      onDidDismiss={(e) => handleDismissModal(e)}
    >
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="primary">
              <IonButton color="medium" onClick={(e) => handleDismissModal(e)}>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        {children}
      </IonContent>
    </IonModal>
  );
}
