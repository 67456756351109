import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { FormEvent } from 'react';
import { useForm, Controller } from 'react-hook-form';

export enum TaskTypes {
  default = 'default',
  once_daily = 'once_daily', // can be claimed once daily
  oneoff = 'oneoff', // can be claimed once
}

export interface FormProp {
  title: string;
  description: string;
  credit: number;
  type: string;
  onSubmit: Function;
}
const TaskForm = ({ onSubmit, ...taskModel }: any) => {
  const { control, formState, getValues } = useForm({
    defaultValues: { ...taskModel },
    mode: 'onBlur',
  });

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    onSubmit(getValues());
  }

  // const showError = (_fieldName: string) => {
  //   const error = errors[_fieldName];
  //   return error ? (
  //     <div style={{ color: 'red', fontWeight: 'bold' }}>
  //       {error.message || 'Field Is Required'}
  //     </div>
  //   ) : null;
  // };

  const QuestTypeField = (
    <IonItem>
      <IonLabel>Quest type</IonLabel>
      <Controller
        as={
          <IonSelect interface="popover" placeholder="Select One">
            {Object.keys(TaskTypes).map((type, key) => (
              <IonSelectOption key={key} value={type}>
                {type.replace('_', ' ')}
              </IonSelectOption>
            ))}
          </IonSelect>
        }
        control={control}
        onChangeName="onIonChange"
        name="type"
      />
    </IonItem>
  );

  const creditField = (
    <IonItem>
      <IonLabel position="floating">Credit</IonLabel>
      <Controller
        as={<IonInput type="number" placeholder="credit" name="credit" />}
        control={control}
        onChangeName="onIonChange"
        name="credit"
        rules={{
          required: true,
          min: 0,
        }}
      />
    </IonItem>
  );

  const titleField = (
    <IonItem>
      <IonLabel position="floating">Title</IonLabel>
      <Controller
        as={IonInput}
        control={control}
        onChangeName="onIonChange"
        name="title"
        rules={{
          required: true,
          minLength: { value: 1, message: 'Must be more than 3 chars long' },
          maxLength: {
            value: 50,
            message: 'Must be less than 50 chars long',
          },
        }}
      />
    </IonItem>
  );

  const descriptionField = (
    <IonItem>
      <IonLabel position="stacked">Description</IonLabel>
      <Controller
        as={<IonTextarea rows={2} placeholder="Enter any notes here..." />}
        control={control}
        onChangeName="onIonChange"
        name="description"
      />
    </IonItem>
  );

  return (
    <form onSubmit={handleSubmit} className="p-3">
      {titleField}
      {/* {showError('title')} */}
      {/* {showError('description')} */}
      {creditField}
      {/* {showError('credit')} */}
      {QuestTypeField}
      {descriptionField}
      <IonButton
        className="m-3"
        expand="block"
        type="submit"
        disabled={formState.isValid === false}
      >
        submit
      </IonButton>
    </form>
  );
};

export default TaskForm;
