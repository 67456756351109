import { API, graphqlOperation, Hub } from 'aws-amplify';
import { UserProfile } from '../models/index';
import { profileByCid, listRewards, listTasks } from '../graphql/queries';
import { todayDateRange } from '../utils/timehelper';
import { ProfileQueryVariables, profileQuery } from '../models';
import {
  ListRewardsQueryVariables,
  ListTasksQueryVariables,
  UpdateUserMutationVariables,
} from '../API';
import { ErrorHandle } from '../utils/error.service';
import { GraphQLResult } from '../models/typing';
import { updateUser } from '../graphql/mutations';
import { Chanels, MutationEvents } from '../_store';
import { DayPlanInput } from '../API';

export enum HubSourceKey {
  actions = 'actions',
  rewards = 'rewards',
  tasks = 'tasks',
  dayPlan = 'dayPlan',
  pid = 'pid',
  profile = 'profile',
}
export class ProfileService {
  static getProfile(): UserProfile {
    return JSON.parse(localStorage.getItem(HubSourceKey.profile) || '');
  }

  static async makePlan(dayPlan: DayPlanInput) {
    const currentPlan = JSON.parse(
      localStorage.getItem(HubSourceKey.dayPlan) || '{}'
    );
    const data: UpdateUserMutationVariables = {
      input: {
        id: localStorage.getItem(HubSourceKey.pid) || '',
        dayPlan: {
          ...currentPlan,
          ...dayPlan,
        },
      },
    };
    try {
      await API.graphql(graphqlOperation(updateUser, data));
      Hub.dispatch(Chanels.Mutation, {
        event: MutationEvents.dayPlanUpdated,
        message: MutationEvents.dayPlanUpdated,
      });
    } catch (error) {
      ErrorHandle.handle(error);
    }
  }

  static async fetchFull() {
    try {
      const variables: ProfileQueryVariables = {
        id: localStorage.getItem(HubSourceKey.pid) || '',
        start: todayDateRange[0],
        end: todayDateRange[1],
      };
      const data = await API.graphql(graphqlOperation(profileQuery, variables));
      const user = (data as GraphQLResult<any>).data.getUser;
      const { actions, rewards, tasks, dayPlan, ...rest } = user;
      localStorage.setItem(HubSourceKey.actions, JSON.stringify(actions.items));
      localStorage.setItem(HubSourceKey.rewards, JSON.stringify(rewards.items));
      localStorage.setItem(HubSourceKey.tasks, JSON.stringify(tasks.items));
      localStorage.setItem(HubSourceKey.dayPlan, JSON.stringify(dayPlan));
      localStorage.setItem(HubSourceKey.profile, JSON.stringify(rest));
      return (data as any).data.getUser;
    } catch (err) {
      ErrorHandle.handle(err.errors);
    }
    await document?.querySelector('ion-item-sliding')?.closeOpened();
  }

  static async fetchProfile(username: string) {
    if (localStorage.getItem(HubSourceKey.pid)) {
      return this.fetchFull();
    }
    try {
      const raw = await API.graphql(
        graphqlOperation(profileByCid, {
          cid: username,
        })
      );
      const profile = (raw as any).data.profileByCid.items[0];
      // localStorage.setItem('profile', JSON.stringify(profile));
      localStorage.setItem(HubSourceKey.pid, profile.id);
      await this.fetchFull();
      return profile;
    } catch (err) {
      ErrorHandle.handle(err);
    }
  }

  static async fetchRewards() {
    try {
      const variables: ListRewardsQueryVariables = {
        filter: {
          user: { eq: localStorage.getItem(HubSourceKey.pid) },
        },
      };
      const data = await API.graphql(graphqlOperation(listRewards, variables));
      const rewards = (data as any).data.listRewards.items;
      return rewards;
    } catch (err) {
      ErrorHandle.handle(err);
    }
  }

  static async fetchTasks() {
    try {
      const variables: ListTasksQueryVariables = {
        filter: {
          user: { eq: this.getProfile().id },
        },
      };
      const data = await API.graphql(graphqlOperation(listTasks, variables));
      const tasks = (data as any).data.listTasks.items;
      return tasks;
    } catch (err) {
      ErrorHandle.handle(err);
    }
    document?.querySelector('ion-item-sliding')?.closeOpened();
  }
}
