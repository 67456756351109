import { IonList, IonModal, IonSearchbar, IonListHeader } from '@ionic/react';
import React, { useState } from 'react';
import moment from 'moment';
import { AddActivity } from '../addActivityButton';
import TaskModal from './taskModal';
import { TaskTypes } from './taskForm';
import { isDailyTaskFinishedToday } from './taskItemDisplay';
import { SlidingItem } from './taskSlidingItem';
import { ActivityNoteItem } from '../activityNoteItem';
import { PomodoroModal } from '../pomodoro/pomodoroModal';
import { SimpleActivityInput } from '../../API';
import { useHubState } from '../../_store';
import { HubSourceKey } from '../../services/profile.service';

export const TaskList = () => {
  const [tasks] = useHubState(HubSourceKey.tasks);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showNoteInput, setShowNoteInput] = useState<boolean>(false);
  const [seletedTask, setSelectedTask] = useState<SimpleActivityInput>();
  const [showPomodoro, setShowPomodoro] = useState<boolean>();
  const [searchText, setSearchText] = useState<string>('');

  function shouldDisplayInMain(task: SimpleActivityInput): boolean {
    // Not finished oneoff
    const finishedOneOff = !!task.finished && task.type === TaskTypes.oneoff;
    // Not finished today once_only
    const doneTodayOnceDaily =
      moment().isSame(task.finished, 'day') &&
      task.type === TaskTypes.once_daily;
    let matchSearch = true;
    if (searchText.length && task.title && task.description) {
      matchSearch =
        task.title.toLowerCase().includes(searchText.toLowerCase()) ||
        task.description.toLowerCase().includes(searchText.toLowerCase());
    }
    return !(finishedOneOff || doneTodayOnceDaily) && matchSearch;
  }

  function makeSlidingItems(items: SimpleActivityInput[]) {
    return items?.map((task, i) =>
      SlidingItem({
        task,
        i,
        setSelectedTask,
        setShowModal,
        setShowNoteInput,
        setShowPomodoro,
      })
    );
  }
  const finishedItems = makeSlidingItems(
    tasks?.filter((t: SimpleActivityInput) => isDailyTaskFinishedToday(t))
  );

  const items = makeSlidingItems(
    tasks
      ?.filter((t) => shouldDisplayInMain(t))
      .sort((a, b) => b.credit - a.credit)
  );

  function handleDismissModal(shouldRefresh = false) {
    setShowModal(false);
    setShowNoteInput(false);
    setShowPomodoro(false);
  }
  return (
    <div>
      <IonSearchbar
        value={searchText}
        onIonChange={(e: any) => setSearchText(e.detail.value)}
      ></IonSearchbar>
      <IonList>
        <IonListHeader>
          <h3 className="mx-3 text-lg"> Earn Bounty</h3>
        </IonListHeader>
        {items}
        <AddActivity type="task" />
        {finishedItems}
      </IonList>
      <IonModal
        swipeToClose={true}
        isOpen={showModal}
        onDidDismiss={() => handleDismissModal()}
        cssClass="my-custom-class"
      >
        {showNoteInput && seletedTask ? (
          <ActivityNoteItem
            type="task"
            activity={seletedTask}
            finishAddCallback={handleDismissModal}
          />
        ) : (
          ''
        )}
        {showPomodoro && seletedTask ? (
          <PomodoroModal
            task={seletedTask}
            showModalHandler={handleDismissModal}
          />
        ) : (
          ''
        )}
        {!(showPomodoro || showNoteInput) && seletedTask ? (
          <TaskModal task={seletedTask} showModalHandler={handleDismissModal} />
        ) : (
          ''
        )}
      </IonModal>
    </div>
  );
};
