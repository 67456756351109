import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonIcon,
  IonButtons,
} from '@ionic/react';
import React from 'react';
import TaskForm from './taskForm';
import { close } from 'ionicons/icons';
import { TaskModel } from '../../models';
import { API, graphqlOperation, Hub } from 'aws-amplify';
import { updateTask } from '../../graphql/mutations';
import { UpdateTaskMutationVariables } from '../../API';
import { Chanels, MutationEvents } from '../../_store';
import { ErrorHandle } from '../../utils/error.service';

interface TaskModalProps {
  task: any;
  showModalHandler: Function;
}

const TaskModal = (props: TaskModalProps) => {
  const { task, showModalHandler } = props;
  const handleSubmit = async (data: Partial<TaskModel>) => {
    const variables: UpdateTaskMutationVariables = {
      input: {
        ...task,
        ...data,
        credit: +(data as any).credit,
      },
    };
    try {
      await API.graphql(graphqlOperation(updateTask, variables));
      Hub.dispatch(Chanels.Mutation, {
        event: MutationEvents.activityEdited,
        message: MutationEvents.activityEdited,
      });
      showModalHandler(true);
    } catch (error) {
      ErrorHandle.handle(error);
    }
  };

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit a quest</IonTitle>
          <IonButtons slot="primary">
            <IonButton color="medium" onClick={(e) => showModalHandler(false)}>
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <TaskForm {...task} onSubmit={handleSubmit}></TaskForm>
    </IonContent>
  );
};

export default TaskModal;
