import React from 'react';
import { IonItem, IonLabel, IonThumbnail } from '@ionic/react';

import styles from './trophy-list-item.module.css';

interface TrophyListItemProps {
  dateAcquired: string;
  imageSrc: string;
  name: string;
}

export const TrophyListItem: React.FC<TrophyListItemProps> = React.memo(
  (props) => {
    return (
      <IonItem className={styles.trophy_item}>
        <IonThumbnail slot="start">
          <img src={props.imageSrc} alt={`${props.name}`} />
        </IonThumbnail>
        <IonLabel>
          <h3>{props.name}</h3>
          <p>Acquired: {props.dateAcquired}</p>
        </IonLabel>
      </IonItem>
    );
  }
);
