import {
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonModal,
  IonText,
  IonSearchbar,
  IonListHeader,
} from '@ionic/react';
import React, { useState } from 'react';
import { addAction } from '../../services/action.service';
import { closeOutline, createOutline } from 'ionicons/icons';
import { AddActivity } from '../addActivityButton';
import RewardModal from './rewardModal';
import { ActivityNoteItem } from '../activityNoteItem';
import { RewardModel } from '../../models/index';
import { DeleteRewardMutationVariables } from '../../API';
import { API, graphqlOperation, Hub } from 'aws-amplify';
import { deleteReward } from '../../graphql/mutations';
import { Chanels, MutationEvents } from '../../_store';
import { ErrorHandle } from '../../utils/error.service';
import { HubSourceKey } from '../../services/profile.service';
import { useHubState } from '../../_store/index';

const RewardList = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [seletedReward, setSelectedReward] = useState<RewardModel>();
  const [showNoteInput, setShowNoteInput] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [rewards] = useHubState(HubSourceKey.rewards);

  async function claim(reward: RewardModel) {
    await addAction('reward', reward);
  }

  async function deleteRewardById(rewardId: string) {
    try {
      const variables: DeleteRewardMutationVariables = {
        input: {
          id: rewardId,
        },
      };
      await API.graphql(graphqlOperation(deleteReward, variables));
      Hub.dispatch(Chanels.Mutation, {
        event: MutationEvents.activityDeleted,
        message: MutationEvents.activityDeleted,
      });
    } catch (error) {
      ErrorHandle.handle(error);
    }
  }

  function showModalForSelectedReward(reward: RewardModel): void {
    setSelectedReward(reward);
    setShowModal(true);
  }
  function shouldShow(r: RewardModel): boolean {
    const notFinishedOneOff = !(r.isOneOff && !!r.finished);
    let matchSearch = true;
    if (searchText.length && r.title && r.description) {
      matchSearch =
        r?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
        r?.description?.toLowerCase().includes(searchText.toLowerCase());
    }
    return notFinishedOneOff && matchSearch;
  }
  const items = rewards
    ?.filter(shouldShow)
    .sort((a, b) => b.credit - a.credit)
    .map((reward, i) => (
      <IonItemSliding key={i}>
        <IonItem>
          <IonLabel color={reward.isOneOff ? 'medium' : 'dark'}>
            {reward.title}
          </IonLabel>
          <IonText slot="end" color="secondary">
            {-reward.credit} 🏷
          </IonText>
        </IonItem>
        <IonItemOptions side="end">
          <IonItemOption>
            <IonIcon
              slot="icon-only"
              icon={createOutline}
              onClick={() => {
                showModalForSelectedReward(reward);
              }}
            ></IonIcon>
          </IonItemOption>
          <IonItemOption
            color="danger"
            expandable
            onClick={() => deleteRewardById(reward.id)}
          >
            <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
          </IonItemOption>
        </IonItemOptions>
        <IonItemOptions
          side="start"
          onIonSwipe={() => {
            setSelectedReward(reward);
            setShowNoteInput(true);
            setShowModal(true);
          }}
        >
          <IonItemOption expandable onClick={() => claim(reward)}>
            Claim
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    ));

  return (
    <div>
      <IonSearchbar
        value={searchText}
        onIonChange={(e: any) => setSearchText(e.detail.value)}
      ></IonSearchbar>
      <IonList>
        <IonListHeader>
          <h3 className="m-3 text-lg"> Enjoy Reward</h3>
        </IonListHeader>
        {items}
        <AddActivity type="reward" />
      </IonList>
      <IonModal
        swipeToClose={true}
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        cssClass="my-custom-class"
      >
        {showNoteInput && seletedReward ? (
          <ActivityNoteItem
            type="task"
            activity={seletedReward}
            finishAddCallback={() => {
              setShowModal(false);
              setShowNoteInput(false);
            }}
          />
        ) : (
          <RewardModal reward={seletedReward} showModalHandler={setShowModal} />
        )}
      </IonModal>
    </div>
  );
};

export default RewardList;
