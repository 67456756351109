export const browserService = {
  appvis: function (): [string, string] {
    let stateKey: any, eventKey: any;
    const keys = {
      hidden: 'visibilitychange',
      webkitHidden: 'webkitvisibilitychange',
      mozHidden: 'mozvisibilitychange',
      msHidden: 'msvisibilitychange',
    };
    for (stateKey in keys) {
      if (stateKey in document) {
        eventKey = keys[stateKey];
        break;
      }
    }
    return [stateKey, eventKey];
  },
};
