import moment from 'moment';
import { utcToZonedTime, format } from 'date-fns-tz';

function getDateQueryString(
  date: Date,
  timeZone: string = 'Europe/London'
): string {
  const pattern = `yyyy-MM-dd`;
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, pattern, { timeZone });
}
const today = new Date();
const todayString = getDateQueryString(today);
const tomorrow = getDateQueryString(moment().add(1, 'day').toDate());
const monday = moment().isoWeekday('Monday').toDate();
const sunday = moment().isoWeekday('Sunday').toDate();
export const todayDateRange = [todayString, tomorrow];
export const thisWeekRange = [
  getDateQueryString(monday),
  getDateQueryString(sunday),
];
