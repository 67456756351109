import React from 'react';
import { IonList, IonLabel, IonNote, IonItem } from '@ionic/react';
import moment from 'moment';

interface ProfileListProps {
  class: string;
  createdAt: string;
  level: number;
  points: number;
  rank: string;
}

export const ProfileList: React.FC<ProfileListProps> = (props) => {
  return (
    <>
      <IonList>
        <IonItem>
          <IonLabel>Questing Since:</IonLabel>
          <IonNote slot="end">
            {moment(props.createdAt).format('MMMM Do, YYYY')}
          </IonNote>
        </IonItem>
        <IonItem>
          <IonLabel>Class:</IonLabel>
          <IonNote slot="end">{props.class}</IonNote>
        </IonItem>
        <IonItem>
          <IonLabel>Current Rank:</IonLabel>
          <IonNote slot="end">{props.rank}</IonNote>
        </IonItem>
        <IonItem>
          <IonLabel>Current Level:</IonLabel>
          <IonNote slot="end">{props.level}</IonNote>
        </IonItem>
        <IonItem>
          <IonLabel>Current XP:</IonLabel>
          <IonNote slot="end">{props.points}</IonNote>
        </IonItem>
      </IonList>
    </>
  );
};
