import React from 'react';
import moment from 'moment';
import { ActionModel } from '../../models/index';
export interface VerticalTimelineProps {
  items: ActionModel[];
}
export function VerticalTimeline(props: VerticalTimelineProps) {
  const { items } = props;
  return (
    <div className="container mx-auto">
      {items
        .sort((a, b) => +moment(b.createdAt) - +moment(a.createdAt))
        .map((action, index) => (
          <div key={index} className="max-w-xl mx-auto flex px-5 py-2">
            <div className="w-1/3 text-sm">
              {moment(action.createdAt).fromNow()}
            </div>
            <div className="w-2/3 ml-3">
              <p className="text-base text-gray-900">{action.description}</p>
              <p className="text-base text-gray-600">{action.note}</p>
            </div>
          </div>
        ))}
    </div>
  );
}
