import React, { useState, useEffect } from 'react';
import { TaskModel } from '../../models/index';
import { ProfileService } from '../../services/profile.service';
import { TaskTypes } from '../quests/taskForm';
import { DayPlanInput, SimpleActivityInput } from '../../API';
import {
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from '@ionic/react';
import { addCircle, removeCircle } from 'ionicons/icons';
interface IProps {
  quests: TaskModel[] | undefined;
  type: 'quest' | 'habit' | false;
  onSuccess: Function;
}

export function DesignMyDay(props: IProps) {
  const [mainTasks, setMainTasks] = useState<SimpleActivityInput[]>([]);
  const { quests, type } = props;
  useEffect(() => {
    const dayPlan: DayPlanInput = JSON.parse(
      localStorage.getItem('profile') || ''
    ).dayPlan;
    if (!dayPlan) return;
    if (type === 'habit') {
      setMainTasks(dayPlan.habits || []);
    } else {
      setMainTasks(dayPlan.main || []);
    }
    return () => {};
  }, [type]);

  function getList() {
    const filterFn = (quest: TaskModel) => {
      if (quest.type === TaskTypes.oneoff && quest.finished) {
        return false;
      }
      if (type === 'quest') {
        return quest.type !== TaskTypes.once_daily;
      } else if (type === 'habit') {
        return quest.type === TaskTypes.once_daily;
      }
      return true;
    };
    return quests?.filter((q) => filterFn(q)) || [];
  }

  function remove(id: string) {
    setMainTasks(mainTasks.filter((t) => t.id !== id));
  }

  function add(task: TaskModel) {
    setMainTasks([...mainTasks, task]);
  }
  return (
    <>
      {mainTasks.map((t: SimpleActivityInput, i: number) => (
        <IonCard key={i}>
          <IonCardHeader>
            <IonCardTitle>
              <span className="text-lg"> {t.title}</span>
              <button
                className="float-right focus:outline-none"
                onClick={(e) => remove(t.id)}
              >
                <IonIcon icon={removeCircle} />
              </button>
            </IonCardTitle>
            <IonCardSubtitle>{t.description}</IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
      ))}

      <h3 className="text-lg text-center">Available Quests</h3>
      {getList().map((task, i) => {
        return (
          <IonCard
            key={i}
            hidden={mainTasks.findIndex((a) => a.id === task.id) >= 0}
          >
            <IonCardHeader>
              <IonCardTitle>
                <span className="text-lg"> {task.title}</span>
                <button
                  hidden={mainTasks.length > 2}
                  className="float-right focus:outline-none"
                  onClick={(e) => add(task)}
                >
                  <IonIcon icon={addCircle} />
                </button>
              </IonCardTitle>
              <IonCardSubtitle>{task.description}</IonCardSubtitle>
            </IonCardHeader>
          </IonCard>
        );
      })}

      <IonButton
        className="mx-3"
        expand="block"
        onClick={async (e) => {
          await ProfileService.makePlan(
            type === 'quest' ? { main: mainTasks } : { habits: mainTasks }
          );
          props.onSuccess(e);
        }}
      >
        Save my design
      </IonButton>
    </>
  );
}
