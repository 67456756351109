import gql from 'graphql-tag';
import { TaskModel, ActionModel, RewardModel } from '../models/index';
import { DayPlanInput } from '../API';

export type ProfileResponse = {
  username: string;
  points: number;
  credits: number;
  dayPlan: DayPlanInput;
  timeZoneOffset: string; //Intl.DateTimeFormat().resolvedOptions().timeZone
  createdAt: string;
  tasks: { items: TaskModel[] };
  actions: { items: ActionModel[] };
  rewards: { items: RewardModel[] };
};

export type ProfileQueryVariables = {
  id: string;
  start: string;
  end: string;
};
export const profileQuery = gql`
  query profile($id: ID!, $start: String!, $end: String!) {
    getUser(id: $id) {
      id
      username
      createdAt
      dayPlan {
        habits {
          id
          title
          type
          description
          credit
          finished
        }
        main {
          id
          title
          type
          description
          credit
          finished
        }
      }
      points
      credits
      timeZoneOffset
      actions(createdAt: { between: [$start, $end] }) {
        items {
          id
          activityId
          description
          note
          createdAt
          credit
        }
      }
      rewards {
        items {
          id
          title
          description
          credit
          timeCost
          moneyCost
          isOneOff
          finished
        }
      }
      tasks {
        items {
          id
          title
          type
          description
          credit
          finished
        }
      }
    }
  }
`;
