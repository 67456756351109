/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cid
      username
      timeZoneOffset
      credits
      points
      dayPlan {
        habits {
          id
          type
          title
          description
          credit
          finished
        }
        main {
          id
          type
          title
          description
          credit
          finished
        }
      }
      createdAt
      updatedAt
      actions {
        items {
          id
          activityId
          user
          description
          note
          credit
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          user
          type
          title
          description
          credit
          finished
          createdAt
          updatedAt
        }
        nextToken
      }
      rewards {
        items {
          id
          user
          title
          description
          credit
          type
          timeCost
          moneyCost
          isOneOff
          finished
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cid
        username
        timeZoneOffset
        credits
        points
        createdAt
        updatedAt
        actions {
          nextToken
        }
        tasks {
          nextToken
        }
        rewards {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      user
      type
      title
      description
      credit
      finished
      createdAt
      updatedAt
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        type
        title
        description
        credit
        finished
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReward = /* GraphQL */ `
  query GetReward($id: ID!) {
    getReward(id: $id) {
      id
      user
      title
      description
      credit
      type
      timeCost
      moneyCost
      isOneOff
      finished
      createdAt
      updatedAt
    }
  }
`;
export const listRewards = /* GraphQL */ `
  query ListRewards(
    $filter: ModelRewardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        title
        description
        credit
        type
        timeCost
        moneyCost
        isOneOff
        finished
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      activityId
      user
      description
      note
      credit
      createdAt
      updatedAt
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activityId
        user
        description
        note
        credit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const profileByCid = /* GraphQL */ `
  query ProfileByCid(
    $cid: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileByCid(
      cid: $cid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cid
        username
        timeZoneOffset
        credits
        points
        createdAt
        updatedAt
        actions {
          nextToken
        }
        tasks {
          nextToken
        }
        rewards {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const profileByUsername = /* GraphQL */ `
  query ProfileByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cid
        username
        timeZoneOffset
        credits
        points
        createdAt
        updatedAt
        actions {
          nextToken
        }
        tasks {
          nextToken
        }
        rewards {
          nextToken
        }
      }
      nextToken
    }
  }
`;
