import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonToggle,
} from '@ionic/react';
import React, { FormEvent } from 'react';
import { useForm, Controller } from 'react-hook-form';

export interface FormProp {
  title: string;
  description: string;
  credit: number;
  timeCost: number;
  moneyCost: number;
  type: number;
  isOneOff: boolean;
  onSubmit: Function;
}
const RewardForm = ({
  title,
  description,
  credit,
  timeCost,
  moneyCost,
  type,
  isOneOff,
  onSubmit,
}: any) => {
  const { control, formState, getValues } = useForm({
    defaultValues: {
      title,
      description,
      credit: Math.abs(credit),
      timeCost,
      moneyCost,
      type,
    },
    mode: 'onBlur',
  });

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    onSubmit(getValues());
  }

  // const showError = (_fieldName: string) => {
  //   const error = errors[_fieldName];
  //   return error ? (
  //     <div style={{ color: 'red', fontWeight: 'bold' }}>
  //       {error.message || 'Field Is Required'}
  //     </div>
  //   ) : null;
  // };

  const isOneOffField = (
    <IonItem>
      <IonLabel>One-off</IonLabel>
      <Controller
        as={<IonToggle checked={isOneOff} slot="start" />}
        control={control}
        onChangeName="onIonChange"
        onChange={([e]) => e.detail.checked}
        name="isOneOff"
      />
    </IonItem>
  );

  const titleField = (
    <IonItem>
      <IonLabel position="stacked">Title</IonLabel>
      <Controller
        as={IonInput}
        control={control}
        onChangeName="onIonChange"
        name="title"
        rules={{
          required: true,
          minLength: { value: 1, message: 'Must be more than 3 chars long' },
          maxLength: {
            value: 50,
            message: 'Must be less than 50 chars long',
          },
        }}
      />
    </IonItem>
  );

  const descriptionField = (
    <IonItem>
      <IonLabel position="stacked">Description</IonLabel>
      <Controller
        as={<IonTextarea rows={2} placeholder="Enter any notes here..." />}
        control={control}
        onChangeName="onIonChange"
        name="description"
        rules={{
          required: true,
          minLength: { value: 8, message: 'Must be 8 chars long' },
        }}
      />
    </IonItem>
  );

  const creditField = (
    <IonItem>
      <IonLabel position="stacked">Credit</IonLabel>
      <Controller
        as={<IonInput type="number" placeholder="credit" name="credit" />}
        control={control}
        onChangeName="onIonChange"
        name="credit"
        rules={{
          required: true,
          min: 0,
        }}
      />
    </IonItem>
  );

  // const typeField = (
  //   <IonItem>
  //     <IonLabel position="stacked">Type</IonLabel>
  //     <Controller
  //       as={
  //         <IonSelect value={getValues().type} placeholder="Select One">
  //           <IonSelectOption value="0">Food and Drink</IonSelectOption>
  //           <IonSelectOption value="1">Entertainment</IonSelectOption>
  //           <IonSelectOption value="3">Travel</IonSelectOption>
  //         </IonSelect>
  //       }
  //       control={control}
  //       onChangeName="onIonChange"
  //       name="type"
  //     />
  //   </IonItem>
  // );

  const timeCostField = (
    <IonItem>
      <IonLabel position="stacked">Time Cost</IonLabel>
      <Controller
        as={
          <IonInput
            type="number"
            placeholder="Estimated time quest takes..."
            name="timeCost"
          />
        }
        control={control}
        onChangeName="onIonChange"
        name="timeCost"
        rules={{
          required: true,
          min: 0,
        }}
      />
    </IonItem>
  );

  const moneyCostField = (
    <IonItem>
      <IonLabel position="stacked">Monetary Cost</IonLabel>
      <Controller
        as={
          <IonInput
            type="number"
            placeholder="Estimated monetary cost quest takes..."
            name="moneyCost"
          />
        }
        control={control}
        onChangeName="onIonChange"
        name="moneyCost"
        rules={{
          required: true,
          min: 0,
        }}
      />
    </IonItem>
  );

  return (
    <form onSubmit={handleSubmit} className="p-3">
      {titleField}
      {/* {showError('title')} */}
      {descriptionField}
      {/* {showError('description')} */}
      {creditField}
      {/* {showError('credit')} */}
      {/* {typeField}
      {showError('type')} */}
      {timeCostField}
      {/* {showError('time')} */}
      {moneyCostField}
      {/* {showError('money')} */}
      {isOneOffField}
      <IonButton
        className="m-3"
        expand="block"
        type="submit"
        disabled={formState.isValid === false}
      >
        submit
      </IonButton>
    </form>
  );
};

export default RewardForm;
