import React from 'react';
import { BasePage } from '../../components/layout/base.page';
import { TaskList } from '../../components/quests/taskList';

export const QuestPage = () => {
  return (
    <BasePage title="Quests">
      <TaskList />
    </BasePage>
  );
};
