import React from 'react';
import { UserProfile } from '../../models/index';
import { Auth } from 'aws-amplify';
import { IonButton } from '@ionic/react';
import moment from 'moment';

interface IProps {
  profile: UserProfile;
}
export function UserCard(props: IProps) {
  async function logout() {
    await Auth.signOut();
    window.location.replace('/');
  }
  const { profile } = props;
  const { credits, username, points, timeZoneOffset, createdAt } = profile;
  return (
    <div className="p-3">
      <div className="text-center font-bold">{username}</div>
      <div className="flex my-3">
        <div className="flex-auto  px-4">
          <span role="img" aria-label="credits">
            💰
          </span>
          {credits}
        </div>
        <div className="flex-auto  px-4">
          <span role="img" aria-label="points">
            🎉
          </span>
          {points}
        </div>
      </div>

      <div className="my-3">
        Timezone:{' '}
        {timeZoneOffset || Intl.DateTimeFormat().resolvedOptions().timeZone}
      </div>
      <div className="my-3">Questing since {moment(createdAt).fromNow()}</div>
      <IonButton onClick={logout} expand="full">
        Logout
      </IonButton>
    </div>
  );
}
