import React, { useState } from 'react';
import { SimpleActivityInput } from '../../API';
import { IonButton, IonIcon } from '@ionic/react';
import { rocket } from 'ionicons/icons';
import BaseModal from '../layout/base.modal';
import { DesignMyDay } from './design-my-day';
import { useHubState } from '../../_store/index';
import QuestList from './QuestList';
import { HubSourceKey } from '../../services/profile.service';

export default function MyDayPlaylist() {
  const [showModal, setShowModal] = useState<'habit' | 'quest' | false>(false);
  const [tasks] = useHubState(HubSourceKey.tasks);
  const [actions] = useHubState(HubSourceKey.actions);
  const [dayPlan] = useHubState(HubSourceKey.dayPlan);
  const [syncDayPlan, setSyncDayPlan] = useState(dayPlan);
  React.useEffect(() => {
    const h = dayPlan?.habits?.map((h) => {
      return (
        tasks.find((t) => t.id === h.id) || {
          ...h,
          info: 'this is a deleted task',
        }
      );
    });
    const m = dayPlan?.main?.map((h) => {
      return (
        tasks.find((t) => t.id === h.id) || {
          ...h,
          info: 'this is a deleted task',
        }
      );
    });
    setSyncDayPlan({
      main: m as SimpleActivityInput[],
      habits: h as SimpleActivityInput[],
    });
  }, [dayPlan, tasks]);

  return (
    <div>
      <div className="mb-2 px-4 py-2 text-xl text-a-w24 font-semibold flex align-center justify-between">
        Main quests
        <button
          onClick={(e) => setShowModal('quest')}
          className="p-1 w-10 h-10 border-2 border-a-w24 hover:bg-a-w76 rounded-full focus:outline-none shadow-lg "
        >
          <IonIcon className="w-full h-full" icon={rocket} />
        </button>
      </div>
      {syncDayPlan?.main?.length ? (
        <QuestList quests={syncDayPlan.main} actions={actions} />
      ) : (
        <IonButton onClick={(e) => setShowModal('quest')}>
          <IonIcon icon={rocket} /> Design my day
        </IonButton>
      )}

      <div className="mt-3 mb-2 px-4 py-2 text-xl text-a-w24 font-semibold flex align-center justify-between">
        Habits Today
        <button
          onClick={(e) => setShowModal('habit')}
          className="p-1 w-10 h-10 border-2 border-a-w24 rounded-full focus:outline-none shadow-lg hover:bg-a-w76 "
        >
          <IonIcon className="w-full h-full" icon={rocket} />
        </button>
      </div>
      {syncDayPlan?.habits?.length ? (
        <QuestList quests={syncDayPlan.habits} actions={actions} />
      ) : (
        <IonButton onClick={(e) => setShowModal('habit')}>
          <IonIcon icon={rocket} /> Design my habits
        </IonButton>
      )}
      <BaseModal
        title={`3 main ${showModal}s`}
        showModal={!!showModal}
        handleDismissModal={(e) => setShowModal(false)}
      >
        <DesignMyDay
          type={showModal}
          quests={tasks}
          onSuccess={() => setShowModal(false)}
        />
      </BaseModal>
    </div>
  );
}
