export function sumArr(arr, field) {
  return arr?.reduce((prev, b) => prev + b[field], 0);
}
export function sumPosNumArr(arr, field) {
  return arr?.reduce((prev, b) => {
    if (b[field] > 0) {
      return prev + b[field];
    } else {
      return prev;
    }
  }, 0);
}
